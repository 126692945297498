<template>
  <div class="mt-2 container-fluid">
    <div class="row">
      <div class="col-lg-12 mx-auto">
        <div class="card">
          <div class="card-header">
            <i class="fa fa-tag"></i> Rubros             
          </div>
          <div class="card-body">  
            <div class="row mb-2">
              <div class="col-lg-4">
                <label>Reparticiones:</label>
                <select
                    class="form-select form-select-sm"                        
                    v-model="id_reparticion"
                    @change="listarCategorias()"
                  >
                    <option v-for="(item,index) in reparticiones" :key="index" :value="item.id_reparticion">{{item.descripcion}}</option>
                </select>
              </div>
              <div class="col-lg-4">
                <label>Sub Reparticiones:</label>
                <select
                    class="form-select form-select-sm"                        
                    v-model="id_categoria"
                    @change="listar()"
                  >
                    <option v-for="(item,index) in categorias" :key="index" :value="item.id_categoria">{{item.descripcion}}</option>
                </select>
              </div>
              <div class="col-lg-4">
                <label class="text-white">...</label><br>
                <a class="btn btn-success btn-sm" @click="showModal()"><i class="fa fa-plus"></i> Nuevo</a>
              </div>
            </div>
            <div class="row">             
              <div class="col md-12">
                <div class="card border-primary">                    
                  <div class="card-body">  
                    <div class="table-responsive" style="overflow-y:scroll; height:550px;">
                      <table class="table table-sm table-bordered table-condensed">
                        <thead>
                          <tr class="table-secondary">
                            <th class="text-center">Nro.</th>
                            <th>Gestión</th>
                            <th>Descripción</th>
                            <th>Sub Repartición</th>
                            <th>Acciones</th>
                          </tr>
                        </thead>                          
                        <tbody>
                          <tr v-for="(item, index) in registros" :key="index">
                            <td class="text-center">{{ ((currentPage*perPage) - perPage) + (index+1)}}</td>
                            <td>
                              {{item.gestion}}                   
                            </td>
                            <td>
                              {{ item.descripcion }}                 
                            </td>
                            <td>
                              {{item.categoria}}                   
                            </td>
                           
                            <td>
                              <a class="btn btn-sm btn-warning mr-4" @click="showModal(item)"><i class="fa fa-edit"></i></a>
                              <a class="btn btn-sm btn-danger ml-4" @click="baja(item)"><i class="fa fa-times"></i></a>
                            </td>                      
                          </tr>
                        </tbody>
                      </table>                        
                    </div>

                    <div class="overflow-auto">
                      <b-pagination
                        class="mb-1 mt-2"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        first-text="<<"
                        prev-text="<"
                        next-text=">"
                        last-text=">>"
                        size="sm"
                        @change="cambio"
                        v-if="rows>10"
                      ></b-pagination>
                      <small class="text-muted">Página: {{ currentPage}} | Registros: del {{ ((currentPage*perPage) - perPage)+1 }} al {{currentPage*perPage}} | Total:  {{rows}}</small>                       
                    </div>

                  </div>
                </div>

              </div>
            </div>

          </div>
        </div> 
      </div>
    </div>
    <Loading v-show="cargando" />

    <b-modal
        class="modal fade"
        :title="tituloModal"
        size="md"
        hide-footer
        ref="modalAm"
        :no-close-on-backdrop="true"
    >
      <div class="container">
        <form @submit.prevent="procesar">
          <div class="row">
            <label>Gestión:</label>
            <span
              class="lb-error"
              v-if="!$v.registro.gestion.required"
              >Campo requerido
            </span>
            <input
              class="form-control"
              :class="{ 'is-invalid': $v.registro.gestion.$error }"
              v-model.trim="$v.registro.gestion.$model"
              placeholder="Gestión..."
              type="number"    
              required="true"          
            >
          </div>
          <div class="row">
            <label>Descripción:</label>
            <span
              class="lb-error"
              v-if="!$v.registro.descripcion.required"
              >Campo requerido
            </span>
            <input
              class="form-control"
              :class="{ 'is-invalid': $v.registro.descripcion.$error }"
              v-model.trim="$v.registro.descripcion.$model"
              placeholder="Descripción..."              
              @keyup="registro.descripcion = registro.descripcion.toUpperCase()" 
            >
          </div>
          <div class="row">
            <label>Sub Repartición:</label>            
            <select
              class="form-select form-select"         
              :class="{ 'is-invalid': $v.registro.id_categoria.$error }"               
              v-model.trim="$v.registro.id_categoria.$model"
              required
            >
              <option v-for="(item,index) in categorias" :key="index" :value="item.id_categoria">{{item.descripcion}}</option>
            </select>
          </div>
          <hr>
          <div class="row">
            <div class="col-lg-6">
              <a class="btn btn-secondary float-left" @click="hideModal" >Cancelar</a>
            </div>
            <div class="col-lg-6">
              <button type="submit" v-if="!edicion" class="btn btn-primary float-end" :disabled="$v.$invalid"><i class="fa fa-save"></i> Registrar</button>
              <button type="submit" v-if="edicion" class="btn btn-warning float-end" :disabled="$v.$invalid"><i class="fa fa-edit"></i> Editar</button>
            </div>
          </div>
        </form>
      </div>
    </b-modal>
    
  </div>
</template>

<script>
import { required, minLength , helpers, maxLength} from "vuelidate/lib/validators";
import Loading from '@/components/Loading';
import moment from 'moment';

const letras = helpers.regex("alpha", /^[a-zA-ZñÑ ]*$/);
const numero = helpers.regex("numb",/^[0-9]\d*$/);

export default {
  data() {
    return {
      cargando:false,
      registros: [],
      currentPage:1,
      rows:0,
      perPage:10,
      valor:'',
      reparticiones: [],
      id_reparticion: 1,
      categorias: [],
      id_categoria:1,
      registro:{
        gestion: 0,
        descripcion: '',
        id_categoria: 0
      },
      edicion: false,
      tituloModal: 'Nuevo Registro',
    }
  },
  components: {
    Loading    
  },
  
  methods: {
    async listar() {
      this.cargando = true;      
      try {              
        let resultados = await this.axios.get(`/rubros/${this.id_categoria}/${this.currentPage}/${this.valor}`);
        this.registros = resultados.data.contenido.registros; 
        this.rows = resultados.data.contenido.total;
        this.cargando = false;
      } catch (error) {
        this.cargando = false;
        console.log(error);
      }
    },
    async listarReparticiones() {
      this.cargando = true;      
      try {              
        let resultados = await this.axios.get(`/listarReparticion`);
        this.reparticiones = resultados.data.contenido.registros; 
        this.id_reparticion = this.reparticiones[0].id_reparticion;
        this.listarCategorias();
        this.cargando = false;
      } catch (error) {
        this.cargando = false;
        console.log(error);
      }
    },
    async listarCategorias() {
      this.cargando = true;      
      try {              
        let resultados = await this.axios.get(`/listarCategorias/${this.id_reparticion}`);
        this.categorias = resultados.data.contenido.registros; 
        this.id_categoria = this.categorias[0].id_categoria;
        this.listar();
        this.cargando = false;
      } catch (error) {
        this.cargando = false;
        console.log(error);
      }
    },
    cambio(value){
      this.currentPage = value;
      this.listar();
    },
    formatoFecha(fecha){
      return moment(fecha).format('DD/MM/YYYY');
    },
    formatoHora(fecha){
      return moment(fecha).format('HH:mm:ss');
    },
    procesar(){
      this.$v.$touch();
      if(this.$v.invalid){
        return;
      }
      else{
        if(this.edicion){this.editar();}else{this.guardar();}
      } 
    },
    async guardar(){
      this.cargando = true;
      try {
        await this.axios.post(`/rubro`, this.registro);        
        this.hideModal();
        this.cargando = false;        
        this.listar();
        this.$alert("Se agregó correctamente","Atención","success"); 
      } catch (error) {
        console.log(error);
        this.cargando = false;
      }
    },
    async editar(){
      this.cargando = true;
      try {
        await this.axios.put(`/rubro/${this.registro.id_rubro}`, this.registro);        
        this.hideModal();
        this.cargando = false;        
        this.listar();
        this.$alert("Se editó correctamente","Atención","success"); 
      } catch (error) {
        console.log(error);
        this.cargando = false;
      }
    },
    baja(item) {
      this.$confirm("Se dará de baja el registro, ¿Está seguro?", "Atención", "question", { confirmButtonText: 'SI',cancelButtonText: 'Cancelar' }).then(async (result) => {        
        if (result) {
          try {
            await this.axios.patch(`/rubro/${item.id_rubro}`);
            this.listar();
            this.$alert("Se dio de baja correctamente","Atención","success");  
          } catch (error) {
            console.log(error);
          }
        }
      }).catch(()=>{});
    },
    showModal(item) {
      if(item!=undefined){
        this.registro = item;
        this.edicion= true;
        this.$refs["modalAm"].show();
        this.tituloModal='Editar Registro';
      }else{
        this.registro = {
          gestion: 0,
          descripcion: '',
          id_categoria: ''
        };
        this.edicion= false;
        this.$refs["modalAm"].show();
        this.tituloModal='Nuevo Registro';
      }      
    },
    hideModal() {
      this.$refs["modalAm"].hide(); 
    }  
  },
  created(){    
    this.listarReparticiones();
  },
  validations: {
    registro: {
      gestion: { required, numero, maxLength:maxLength(4) },
      descripcion: { required, letras, minLength: minLength(5) },
      id_categoria: { required }
    }
  }
}
</script>
